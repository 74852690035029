import { Button, Toggle } from 'components/ui/Input';
import { Table } from 'components/ui/Table';
import Pagination from 'components/ui/Table/parts/Pagination';
import { NO_DATA_DISPLAY } from 'config/configs';
import { AddressRender, DateRender, DateTimeRender } from 'core/renders';
import { useCrud, useTranslations } from 'hooks';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import PatientModel from '../pages/Patients/models';
import PatientService from '../pages/Patients/services';

const PatientSelectorPortal = forwardRef((props, ref) => {
	const { translate } = useTranslations();

	const onSelect = useRef(null);
	const tableRef = useRef(null);
	const selectedFound = useRef(null);

	const [show, setShow] = useState(false);
	const [selected, setSelected] = useState(null);

	const [queryString, setQueryString] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [displayDischarged, setDisplayDischarged] = useState(false);
	const [data, setData] = useState({ data: [], meta: {} });
	const [collapsed, setCollapsed] = useState(true);

	const service = new PatientService();
	const model = new PatientModel({ canBeModified: false });

	const { getAll, getOne } = useCrud(service);

	const fetchAndLoad = async (l_queryString = null, showAllDischarged = null) => {
		try {
			const showDischargedQuery = showAllDischarged === null ? displayDischarged : showAllDischarged;
			let params = new URLSearchParams(l_queryString || queryString);
			//if it is first time remove status
			if (showAllDischarged === true) {
				params.delete('status');
			}
			if (showDischargedQuery) {
				params.append('ShowAllDischarged', true);
				params.append('status', 3);
			}

			setIsLoading(true);
			const res = await getAll(params.toString());
			setIsLoading(false);
			setData(res);
			if (!selectedFound.current && selected) {
				const selectedPatient = res.data.find((d) => d.id === selected?.id);

				if (selectedPatient) {
					setSelected(selectedPatient);
					setSelectedColumn(selectedPatient);
				} else {
					let sp = await getOne(selected?.id);
					if (!sp.patient.fullName) {
						sp = {
							...sp,
							patient: { ...sp.patient, fullName: sp.patient.firstName + ' ' + sp.patient.lastName },
						};
					}

					setSelectedColumn(sp);
					setSelected(sp);
				}
				selectedFound.current = true;
			}
		} catch (error) {
			console.error('Error on Get All request: ', error);
		}
	};

	const setSelectedColumn = (item) => {
		if (tableRef.current) {
			tableRef.current.setSelectedColumns([item]);
		}
	};

	const closeAndReset = () => {
		setShow(false);
		setSelectedColumn(null);
		setSelected(null);
		setDisplayDischarged(false);
		setData({ data: [], meta: {} });
		setCollapsed(true);

		onSelect.current = null;
	};

	const changePagination = (key, value) => {
		const newPagination = { ...data.meta, [key]: value };
		if (key === 'size') newPagination['page'] = 1;

		setData((prev) => ({ ...prev, meta: newPagination }));

		let params = new URLSearchParams(queryString);
		params.delete('page');
		params.delete('pageSize');
		params.delete('sortBy');
		params.delete('sortAsc');
		params.append('page', newPagination.page);
		params.append('pageSize', newPagination.size);
		if (newPagination.sortBy) {
			params.append('sortBy', newPagination.sortBy);
			params.append('sortAsc', newPagination.sortAsc);
		}
		setQueryString(params.toString());
		fetchAndLoad(params.toString());
	};

	useImperativeHandle(ref, () => ({
		callAction: async (action, data, callback) => {
			switch (action) {
				case 'open':
					setShow(true);
					setSelected(data?.selected || null);

					onSelect.current = callback;
					if (data?.selected?.admissionNr) {
						selectedFound.current = true;
						setTimeout(() => {
							if (data?.selected) setSelectedColumn(data?.selected);
						}, 1500);
					} else {
						selectedFound.current = false;
					}

					break;
				default:
					throw Error(`No action named ${action} for portal ${props.id}`);
			}
		},
	}));

	return (
		<>
			{show &&
				createPortal(
					<div
						onClick={() => closeAndReset()}
						className='absolute z-50 top-0 bottom-0 right-0 left-0 bg-slate-800/50 flex justify-center items-center'
					>
						<div
							onClick={(e) => e.stopPropagation()}
							className='relative flex flex-col h-9/10 w-4/5  rounded-lg bg-white px-4 pt-4 overflow-hidden'
						>
							<div className='flex flex-row  justify-between pt-2 mb-1'>
								<div className='font-medium'>
									<i className='ri-user-6-line'></i>
									<span className='pl-1'>{translate('patientSelect')}</span>
								</div>
								<button onClick={closeAndReset} className='pr-2'>
									<i className='ri-close-line'></i>
								</button>
							</div>

							<div className='flex-1 overflow-auto border-b border-gray-300'>
								<div className='text-xs text-gray-400 pl-2'>
									{translate('toSelectAPatientClickOnItemOnTableAndPressSelectPatientButton')}
								</div>

								{selected && (
									<div className='flex flex-col mt-1 p-2 border-2 bg-slate-50 rounded-md border-slate-100 shadow-md'>
										<div
											className='flex justify-between items-center cursor-pointer'
											onClick={() => setCollapsed(!collapsed)}
										>
											<div className='flex flex-row space-x-2 items-center'>
												<div className='font-thin text-gray-400 text-xs'>
													#{selected?.patient?.patientNr}
												</div>
												<div className='font-bold text-gray-600'>
													{selected?.patient?.fullName}
												</div>
												{selected?.patient?.gender && (
													<div className='font-bold text-gray-600'>
														({selected?.patient?.gender?.name?.charAt(0).toUpperCase()})
													</div>
												)}
											</div>

											<button>
												<i className={`ri-arrow-${collapsed ? 'down' : 'up'}-s-line`}></i>
											</button>
										</div>
										{!collapsed && (
											<div className='mt-2 border-t border-gray-200 pt-2'>
												<div className='grid grid-cols-1 md:grid-cols-2 gap-4 text-xs'>
													<div>
														<div className='pb-1'>
															<span className='font-bold pr-2'>
																{translate('admissionNumber')}:
															</span>
															{selected.admissionNr}
														</div>
														<div className='pb-1'>
															<span className='font-bold pr-2'>
																{translate('patientNumber')}:
															</span>
															{selected?.patient?.patientNr}
														</div>
														<div className='pb-1'>
															<span className='font-bold pr-2'>{translate('name')}:</span>
															{selected?.patient?.fullName}
														</div>
														<div className='pb-1'>
															<span className='font-bold pr-2'>
																{translate('gender')}:
															</span>
															{selected?.patient?.gender?.name || NO_DATA_DISPLAY}
														</div>
														<div className='pb-1 flex flex-row items-center'>
															<span className='font-bold pr-2'>
																{translate('homeLocation')}:
															</span>
															{AddressRender({
																value: selected?.homeLocation,
																truncateLength: 32,
															})}
														</div>
													</div>
													<div>
														<div className='pb-1'>
															<span className='font-bold pr-2'>
																{translate('status')}:
															</span>
															{selected?.status?.name}
														</div>
														<div className='pb-1'>
															<span className='font-bold pr-2'>
																{translate('taskType')}:
															</span>
															{selected?.patient?.taskType?.name || NO_DATA_DISPLAY}
														</div>

														<div className='pb-1'>
															<span className='font-bold pr-2'>
																{translate('dateOfBirth')}:
															</span>
															{DateRender({ value: selected?.patient?.dateOfBirth })}
														</div>
														<div className='pb-1'>
															<span className='font-bold pr-2'>
																{translate('updatedOn')}:
															</span>
															{DateTimeRender({ value: selected?.updatedOn })}
														</div>
														<div className='pb-1'>
															<span className='font-bold pr-2'>
																{translate('eventTime')}:
															</span>
															{DateTimeRender({ value: selected?.eventDateTime })}
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
								)}

								<Table
									ref={tableRef}
									noPagination
									columnClass='px-6 py-1'
									model={model}
									isLoading={isLoading}
									isExportable={false}
									meta={data.meta}
									data={data.data}
									hasRowActions={true}
									onTableRequestChange={(queryStr) => {
										setQueryString(queryStr);
										fetchAndLoad(queryStr);
									}}
									onRowClick={(item) => {
										setSelectedColumn(item);
										setSelected(item);
									}}
									noActionOnSelect={true}
								/>
							</div>
							<div className='flex flex-row justify-between  items-center'>
								<div className='pl-3'>
									<Button
										disabled={!selected}
										onClick={() => {
											if (onSelect.current && selected)
												onSelect.current({
													selected,
												});
											closeAndReset();
										}}
										className='px-3 py-2 text-xs'
									>
										{translate('selectPatient')}
									</Button>
								</div>

								<div className=' flex flex-row justify-center space-x-4 items-center'>
									<div>
										<div className='flex flex-row justify-start items-center space-x-3 rounded-lg p-1.5 border-2 border-slate-100  '>
											<div className=' text-xs text-gray-500'>
												{translate('displayAllDischargedPatients')}
											</div>
											<Toggle
												checked={displayDischarged}
												onClick={() => {
													const showAllDischarged = !displayDischarged;
													setDisplayDischarged(showAllDischarged);
													fetchAndLoad(queryString, showAllDischarged);
												}}
											/>
										</div>
									</div>
									<Pagination
										page={data.meta.page || 1}
										size={data.meta.size || 20}
										total={data.meta.total || 0}
										onPageChange={(newPage) => changePagination('page', newPage)}
										onPageSizeChange={(newPageSize) => changePagination('size', newPageSize)}
									/>
								</div>
							</div>
						</div>
						<Tooltip place='bottom' id='table-tooltip' className='z-50' />
					</div>,
					document.body,
				)}
		</>
	);
});

export default PatientSelectorPortal;
